// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/*
*
*
* Colors
*
*
*/
$color-primary: var(--ion-color-primary);
$color-secondary: var(--ion-color-secondary);
$color-tertiary: var(--ion-color-tertiary);
$color-danger: var(--ion-color-danger);
$color-light: var(--ion-color-light);

$color-white: #fff;
$color-black: $color-secondary;
$color-red: $color-danger;
$color-gray: #989aa2;
$color-gray--light: $color-light;
$color-gray--pickslot: #e7e7e7;
$color-advert-saloon: #dfd5cc;
$color-blue: #41acf7;
$color-border: #d8d8d8;

/*
*
*
* Typography
*
*
*/
$font-small: 11px !important;
$font-default: 13px !important;
$font-medium: 16px !important;
$font-large: 24px !important;
$font-ultra: 32px !important;
$font-family-default: 'Muli', sans-serif !important;
$font-family-semibold: "Muli-SemiBold", sans-serif !important;
$font-family-bold: 'Muli-Bold', sans-serif !important;
$font-family-extrabold: 'Muli-Extra-Bold', sans-serif !important;
$font-family-black: "Muli-Black", sans-serif !important;

/*
*
*
* Buttons
*
*
*/
$btn-small-font: $font-small;
$btn-default-font: $font-default;
$btn-medium-font: $font-medium;
$btn-large-font: $font-large;

$btn-small-size: 30px;
$btn-default-size: 40px;
$btn-large-size: 60px;


/*
*
*
* Fonts
*
*
*/

@font-face {
  font-family: "Muli";
  src: url("/assets/fonts/Muli/Muli.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Muli-Bold";
  src: url("/assets/fonts/Muli/Muli-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Muli-SemiBold";
  src: url("/assets/fonts/Muli/Muli-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Muli-Extra-Bold";
  src: url("/assets/fonts/Muli/Muli-ExtraBold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Muli-Black";
  src: url("/assets/fonts/Muli/Muli-Black.ttf");
  font-weight: normal;
  font-style: normal;
}


/*
*
*
* Custom icons
*
*
*/

ion-icon {

  color: $color-black;

  &[class*="facebook"] {
    height: 24px;
    width: 24px;
    background: url(assets/icons/facebook.svg) center (center / contain)
      no-repeat;
  }

  &[class*="instagram"] {
    height: 24px;
    width: 24px;
    background: url(assets/icons/instagram.svg) center (center / contain)
      no-repeat;
  }

  &[class*="twitter"] {
    height: 24px;
    width: 24px;
    background: url(assets/icons/twitter.svg) center (center / contain)
      no-repeat;
  }

  &[class*="arrow-left"] {
    visibility: visible;
    height: 24px;
    width: 24px;
    background: url(assets/icons/left.svg) center (center / contain) no-repeat;
  }

  &[class*="arrow-right"] {
    visibility: visible;
    height: 24px;
    width: 24px;
    background: url(assets/icons/right.svg) center (center / contain) no-repeat;
  }

  &[class*="visibility"] {
    visibility: visible;
    height: 24px;
    width: 24px;
    background: url(assets/icons/visibility.svg) center (center / contain)
      no-repeat;
  }

  &[class*="profile"] {
    visibility: visible;
    height: 21px;
    width: 21px;
    background: url(assets/icons/profile.svg) center (center / contain)
      no-repeat;
  }

  &[class*="close"] {
    visibility: visible;
    height: 24px;
    width: 24px;
    background: url(assets/icons/close.svg) center (center / contain) no-repeat;
  }

  &[class*="saloon"] {
    visibility: visible;
    height: 40px;
    width: 40px;
    background: url(assets/icons/saloon.svg) center (center / contain) no-repeat;
  }

  &[class*="menu"] {
    visibility: visible;
    height: 24px;
    width: 24px;
    background: url(assets/icons/menu.svg) center (center / contain) no-repeat;
  }

  &[class*="sms"] {
    visibility: visible;
    height: 40px;
    width: 40px;
    background: url(assets/icons/SMS.svg) center (center / contain) no-repeat;
  }

  &[class*="calendar"] {
    visibility: visible;
    height: 40px;
    width: 40px;
    background: url(assets/icons/calendar.svg) center (center / contain)
      no-repeat;
  }

  &[class*="checkmark"] {
    visibility: visible;
    height: 100px;
    width: 90px;
    background: url(assets/icons/check.svg) center (center / contain) no-repeat;
  }

  &[class*="filter"] {
    visibility: visible;
    height: 24px;
    width: 24px;
    background: url(assets/icons/filter.svg) center (center / contain) no-repeat;
  }

  &[class*="dropdown"] {
    visibility: visible;
    height: 16px;
    width: 16px;
    background: url(assets/icons/dropdown.svg) center (center / contain)
      no-repeat;
  }
}

html {
  height: 101vh !important;
  max-height: none !important;
  overflow: scroll !important;
}

a {
  background-color: transparent;
  color: $color-secondary;
  text-decoration: underline;
}

.btn {
  &--small {
    font-size: $btn-default-font;
    font-weight: bold;
    text-transform: none;
    line-height: 16px;
    height: $btn-small-size;
    --box-shadow: none;
    position: relative;
  }

  &--default {
    font-size: $btn-default-font;
    font-weight: bold;
    text-decoration: none;
    height: $btn-default-size;
    --box-shadow: none;
    position: relative;
  }

  &--large {
    font-family: $font-family-extrabold;
    font-size: $btn-medium-font !important;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 0.5pt;
    height: $btn-large-size;
    --box-shadow: none;
    position: relative;
  }
}

.search-category {
  --indicator-color-checked: $color-black;
  color: $color-black !important;
  height: 35px;
  min-height: 35px;
  background: $color-gray--light;
  width: 30%;

  ion-label {
    margin: 0;
  }

  &.segment-button-checked {
    background: $color-black !important;
    color: $color-white !important;
  }

  &.-filter {
    background: none;
  }
}

.form-field-hint--error {
  color: var(--ion-color-tertiary, #3880ff);
}

.fb-btn {
  --background: #395695;
  --color: #ffffff;
}

.shop-listing__img,
.advertisment--saloon__img {
  height: 190px;
  object-fit: cover;
}

.stepper {
  &__btn {
    &--active {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        border: 15px solid $color-black;
        border-left: 1px solid $color-black;
        border-right: 7px solid transparent;
        transform: scale(-1) translate(7px);
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -7px;
        border: 20px solid transparent;
        border-left: 1px solid transparent;
        border-right: 7px solid $color-black;
        transform: scale(-1) translateY(50%);
        z-index: 1;
      }
    }
  }
}

.slide {
  display: flex;
  align-items: center;
}

.day {
  padding: 6px;
  margin: 0 5px;
  flex-basis: 25%;
  border: none;

  &--closed {
    div,
    span {
      color: $color-gray;
    }
  }

  &--selected {
    background: $color-primary;
  }

  &__day-of-week,
  &__price {
    font-family: $font-family-bold;
    font-size: $font-small;
    line-height: 16px;
  }

  &__day-of-month {
    font-family: $font-family-bold;
    font-size: $font-large;
    line-height: 30px;
    margin-top: 5px;
    width: 80px;
  }

  &__month {
    font-size: $font-small;
    line-height: 16px;
    padding-bottom: 8px;
  }
}

.combination--picked {
  --background: #555d50;
}

/*
*
*
* Global header styles
*
*
*/

.header {
  $self: &;
  padding-top: 6px !important;
  padding-bottom: 6px;

  &::after {
    content: none !important;
  }

  &.-primary {
    background: $color-primary;
  }

  &-modal {
    #{$self}-title {
      font-family: $font-family-black;
      font-size: $font-ultra;
      line-height: 40px;
      padding: 0 16px;
      margin: 20px 0 14px;
      position: relative;
    }

    ion-button {
      font-size: 19px;
    }
  }

  img {
    width: 80px;
    height: 20px;
    display: block;
  }

  &-title {
    font-size: $font-medium;
    font-family: $font-family-bold;
    line-height: 22px;
    padding-inline-start: 60px;
    padding-inline-end: 60px;
  }

  &-buttons {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;

    &__btn {
      position: relative;
      margin-inline-start: 10px !important;

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  .notification-holder {
    position: absolute;
    bottom: 4px;
    right: 10px;
    background: $color-red;
    z-index: 10;
    border-radius: 50%;

    div {
      position: relative;
      display: block;
      height: 11px;
      width: 11px;
    }

    &__number {
      font-family: $font-family-bold;
      font-size: 8px !important;
      line-height: 10px;
      position: absolute;
      top: 0;
      left: 2.5px;
      line-height: 10px;
      color: $color-white;
    }
  }
}

.search-modal {
  &__search {
    --placeholder-color: %color-black;
    --placeholder-font-weight: normal;
    line-height: 16px;
    padding: 0;
    margin-bottom: 24px;

    input {
      padding-top: 13px !important;
      padding-bottom: 13px !important;
      box-shadow: none !important;
      font-size: $font-default;
    }

    ion-icon {
      top: 50% !important;
      transform: translateY(-50%);

      &.searchbar-clear-icon {
        transform: none !important;
        top: 0 !important;
      }
    }
  }

  &__list {
    padding: 0;

    &-item {
      &--location {
        font-size: $font-default;
        line-height: 16px;
      }

      background: $color-primary;
      font-family: $font-family-bold;
      font-size: $font-large;
      line-height: 30px;
    }

    ion-icon {
      margin-right: 15.5px;
      font-size: 20px;
    }
  }
}

/*
*
*
* Advertisments
*
*
*/

.advertisment {
  &--saloon {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 40px;

    ion-card-header {
      padding: 24px;
      background-color: $color-advert-saloon;
    }

    p {
      margin: 0;
    }

    &__title {
      font-size: $font-default;
      color: $color-black;
      line-height: $font-medium;
      margin-bottom: 10px;
    }

    &__subtitle {
      font-family: $font-family-bold;
      font-size: $font-medium;
      text-transform: none;
      color: $color-black;
      line-height: 22px;
      max-width: 240px;
      height: 100px;
      margin-bottom: 32px;
    }
  }

  &--black {
    background: $color-black;
    padding: 16px 32px 24px;

    &__logo {
      display: block;
      width: 63px;
    }

    &__title {
      font-family: $font-family-bold;
      font-size: $font-medium;
      line-height: $font-large;
      color: $color-white;
    }

    &__desc {
      font-size: $font-default;
      line-height: $font-medium;
      color: #707070;
      margin-bottom: 40px;
      height:10px;
    }

    .pp-link {
      color: $color-white;
      font-size: $font-default;
    }
  }
}

.sticky {
  &-sale,
  &-discount {
    font-family: $font-family-bold;
    font-size: $font-default;
    line-height: $font-medium;
    position: absolute;
    top: 8px;
    right: 8px;
    height: 35px;
    width: 55px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
  }

  &-sale {
    background: $color-primary;
    color: $color-black;
  }

  &-discount {
    background: $color-tertiary;
    &--hot {
      background: $color-danger;
      width: 70px;
    }
  }
}

/*
*
*
* Pricepilot specific
*
*
*/
.pp-link {
  font-family: $font-family-bold;
  font-size: $font-small;
  line-height: 14px;
  padding-bottom: 1px;
  border-bottom: 2px solid $color-primary;
  text-decoration: none;
  text-transform: uppercase;
}

:root,
:root[mode="ios"],
:root[mode="md"] {
  --ion-font-family: "Muli", sans-serif !important;
  font-family: "Muli", sans-serif !important;
}

/*
*
*
* Form styles
*
*
*/

.form {

  &-filters {
    .close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;

      &--right-spacing {
        right: 10px;
        color: $color-gray;
        font-size: $font-small;
      }
    }

    ion-item {
      --border {
        padding: 10px 15px !important;
        border: 1px solid $color-border;
      }
    }

    input[disabled] {
      opacity: 1 !important;
    }

    ion-label {
      opacity: 1 !important;
    }
  }

  ion-grid,
  ion-col,
  input {
    padding: 0 !important;
  }

  ion-label {
    font-size: $font-default;
    line-height: 16px;
  }

  ion-item {
    position: relative;
    margin-bottom: 24px;
  }

  .item-has-focus .label-stacked.sc-ion-label-md-h {
    color: $color-black;
  }

  .native-input {
    font-family: $font-family-bold;
    font-size: $font-medium;
    line-height: 22px;
    font-weight: bold;
    padding: 0 !important;
  }

  &__submit {
    margin: 18px 0;
  }

  &-checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ion-checkbox {
      margin-right: 16px;
      border: 1px solid $color-gray;
      --border-color: transparent !important;
    }

    &__cb-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &-icon-holder {
    display: block;
    position: absolute;
    bottom: 17px;
    right: 0;
  }
}

.text {
  &--tos {
    font-size: $font-small;
    line-height: 16px;
    margin: 16px 0;

    a {
      font-size: $font-small;
      font-family: $font-family-bold;
      line-height: 16px;
    }
  }
}

.register-prompt {
  font-size: $font-default;
  line-height: 24px;
  margin-bottom: 32px;
  width: 100%;

  a {
    margin-left: 5px;
  }
}

/*
*
*
* Popular saloons
*
*
*/

.popular-saloons {
  margin-bottom: 40px;

  &-title {
    font-family: $font-family-black;
    font-size: $font-ultra;
    line-height: 40px;
  }

  &__img {
    height: 160px;
    object-fit: cover;
  }

  &__name {
    font-family: $font-family-bold; 
    font-size: $font-large;
    line-height: 30px;
    padding-bottom: 1px;
    border-bottom: 4px solid $color-primary;
    display: inline-block;
  }

  &__address {
    font-family: $font-family-bold;
    font-size: $font-default;
    line-height: $font-large;
    margin-bottom: 15px;
  }

  &__desc {
    font-size: $font-default;
    line-height: $font-large;
    height:100px;
  }

  &__rating {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: $font-family-bold;
    font-size: $font-medium;
    line-height: $font-large;
    padding: 8px;
    background: $color-primary;

    span {
      font-family: $font-family-default;
      font-size: $font-small;
      line-height: $font-medium;
      margin-left: 5px;
    }
  }

  &__advert {
    margin: 13px 0 0;
    font-size: $font-small;
    line-height: $font-medium;
    padding: 3px 7px;
    border: 1px solid $color-black;
  }
}

/*
*
*
* Ongoing sales
*
*
*/

.ongoing-sales {
  position: relative;
  margin-bottom: 30px;

  &-title {
    font-family: $font-family-black;
    font-size: $font-ultra;
    line-height: 40px;
  }

  &-btn {
    background: transparent;
    --background: transparent;
    border: 1px solid $color-black;
  }

  &__img {
    height: 230px;
    object-fit: cover;
  }

  &__shop-name {
    font-size: $font-default;
    line-height: $font-medium;
    margin: 9px 0 9px;
  }

  &__service-title {
    font-family: $font-family-bold;
    font-size: $font-medium;
    line-height: $font-large;
    margin: 0 0 18px;
  }

  &__discount {
    font-family: $font-family-bold;
    font-size: $font-medium;
    line-height: $font-large;

    span {
      margin-left: 15px;
      font-family: $font-family-default;
      line-height: $font-medium;
      text-decoration: line-through;
    }
  }
}

.discover-saloons {
  &-title {
    font-family: $font-family-black;
    font-size: $font-large;
    line-height: 40px;
  }

  &__img {
    height: 105px;
    width: 105px;
  }

  &__city {
    display: inline-block;
    font-family: $font-family-bold;
    font-size: $font-medium;
    line-height: $font-large;
    padding-bottom: 1px;
    border-bottom: 4px solid $color-primary;
  }
}

.dropdown {
  position: absolute;
  top: 2px;
  right: 0;

  &-text {
    font-size: $font-default;
    text-decoration: none;
    line-height: $font-medium;
    position: relative;
    padding-right: 20px;
    margin: 16px 16px 16px 16px;
  }

  &-content {
    position: absolute;
    right: 16px;
    z-index: 10;
    bottom: -205px;
    padding: 15px 20px 20px;
    background: $color-white;
    list-style: none;
    text-align: right;
    border: 1px solid $color-border;

    li {
      margin-bottom: 24px;
      font-size: $font-default;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.map-view-shop {
  padding-left: 100px;
  position: relative;
  min-height: 95px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__img {
    display: block;
    height: 65px;
    width: 65px;
    position: absolute;
    top: 15px;
    left: 15px;
  }

  &__name {
    font-family: $font-family-bold;
    font-size: $font-default;
    line-height: $font-medium;
    margin-top: 16px !important;
    margin-bottom: 0 !important;
  }

  &__address {
    font-size: $font-small;
    line-height: $font-default;
    margin-bottom: 10px !important;
    margin-top: 0 !important;
  }

  .simple-rating {
    margin-top: 0 !important;
    margin-bottom: 19px !important;
  }
}

.simple-rating {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-bold;
  font-size: $font-medium;
  line-height: $font-large;

  span {
    font-family: $font-family-default;
    font-size: $font-small;
    line-height: $font-medium;
    margin-left: 5px;
  }
}

.visit-date {
  ion-button {
    --border-radius: 0;
  }
}

.filters-field-title {
  font-size: $font-small;
  padding: 0px 0px 0px 16px;
  margin: 16px 0px 0px 0px;
}

.my-custom-class .alert-button-inner {
  color: $color-black;
}


// Screen size hidden
.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

.clickable { 
  cursor: pointer; 
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}


@media (min-width: 992px) {
  .my-modal {  
     --width:700px;
     --height:700px;
  }
}


.fc-button {
  border-radius: 0px !important;
  background-color:  $color-secondary !important;
}

.fc-event {
  cursor: pointer;
  border-radius: 0px !important;
  // border-color: #3DEBD3 !important;
  //background-color: #3DEBD3 !important;
}


@media screen and (max-width: 576px) { // ionic sm breakpoint at 576px
  .fc-button { // fullcalendar gumbi
    font-size: 12px !important;
  }
  
  .fc-toolbar-title { // datum med gumbi (16. december 2021)
    font-size: 0.8em !important;
  }

  .fc-col-header-cell { // header tekst za dneve (sre. 13.10.)
    font-size:  0.6em !important;
  }

  .fc-timegrid-slot { // tekst ob levi strani (08h, 09h...)
    font-size:  0.7em !important;
  }

  .fc-event-title {
    font-size:  0.8em !important;
  }
}

